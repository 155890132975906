<template>
  <div class="w-full max-w-3xl mx-auto px-[4vw] pb-12">
    <!-- <div class="border bg-slate-50 p-4">
      <div class="min-h-[16rem] flex items-center justify-center">
        <div class="font-bold text-sm text-slate-600 text-center">
          Start
        </div>
      </div>
    </div> -->
    <div class="grid gap-1 mb-6">
      <BlocksCoreText class="text-h1" :data="{ element: 'h1', text: 'Vinspiration.se' }" />
      <!-- <p class="meta opacity-60">{{ term.description }}</p> -->
    </div>
    <div class="grid gap-6" v-auto-animate>
      <div class="border-t pt-6" v-for="post in data?.posts" :key="post.id">
        <NuxtLink :to="post.path" class="grid gap-2">
          <h2 class="font-serif font-bold text-xl md:text-2xl">{{ post.title }}</h2>
          <p class="text-sm opacity-50">Publicerad {{ toDayjs(post.publishedDate).format('dddd D MMMM') }}</p>
          <p class="text-sm">{{ post.excerpt }}</p>
        </NuxtLink>
        <div class="text-sm flex gap-4 mt-4" v-if="post.tags.length">
          <NuxtLink class="underline" :to="tag.slug" v-for="tag in post.tags" :key="tag.id">
            {{ useUcFirst(tag.name) }}
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Fetch posts
const { data } = await useFetch(`/posts`, { 
  key: 'start-posts',
  baseURL: useEnv('cmsBase'),
  query: {
    limit: 20,
  }
})
</script>